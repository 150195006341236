<template>
  <v-app>
      <!-- the main top bar -->
      <v-app-bar
          app
          dark
          clipped-left
      >
          <div style="display:flex;width:100%;max-width: 1600px;">
              <!-- drawer state of this menu -->
              <span
                  class="d-none d-md-block d-lg-block d-xl-block d-sm-block mt-2"
                  v-if="selected_mini_drawer_state"
                  @click="selected_mini_drawer_state = !selected_mini_drawer_state;selected_drawer_state = true"
              >
                  <v-icon aria-label="menu" large>mdi-menu</v-icon>
              </span>
              <span
                  class="d-none d-md-block d-lg-block d-xl-block d-sm-block mt-2"
                  v-else @click="selected_mini_drawer_state = !selected_mini_drawer_state"
              >
                <v-icon aria-label="menu" large>mdi-backburger</v-icon>
              </span>
              <span
                  class="d-md-none d-lg-none d-xl-none d-sm-none mt-2"
                  v-if="selected_drawer_state"
                  @click="selected_drawer_state ? selected_drawer_state = false : selected_drawer_state = true">
                <v-icon aria-label="menu">mdi-backburger</v-icon>
              </span>
              <span
                  class="d-md-none d-lg-none d-xl-none d-sm-none mt-3"
                  v-else @click="selected_drawer_state ? selected_drawer_state = false : selected_drawer_state = true">
                <v-icon aria-label="menu">mdi-menu</v-icon>
              </span>

              <router-link to="/">
                  <v-img
                      :src="require($vuetify.breakpoint.name == 'xs' ? './assets/vs-logo-sm.webp' : './assets/vs-logo-md.webp')"
                      alt="videospace"
                      class="ml-lg-10 ml-xl-10 ml-md-10 ml-sm-10 ml-2 mt-3 mt-sm-1 videospace-logo"
                      aria-label="Videospace"
                  />
              </router-link>

              <!-- seasonal link
              <router-link to="/collection/holiday_action_films">
                <v-icon color="green" style="font-size: 2.2em;text-stroke: 2px black;margin-left:0.5em" aria-label="name">mdi-pine-tree</v-icon>
              </router-link>
              -->

              <!-- master search activators -->
              <v-spacer/>
              <v-text-field
                  v-model="searchPhrase"
                  append-icon="mdi-magnify"
                  :label="$t('search from all videospace')"
                  single-line
                  class="d-none d-sm-block mt-3"
                  @click="searchDialog ? searchDialog = false : searchDialog = true"
              ></v-text-field>
              <v-icon
                  class="d-md-none d-lg-none d-xl-none d-sm-none"
                  @click="searchDialog ? searchDialog = false : searchDialog = true"
                  :aria-label="$t('search')"
              >
                  mdi-magnify
              </v-icon>

              <v-spacer/>

              <notifications
                  v-if="auth_user"
                  :notifications_unseen_count="auth_user.notifications_unseen_count"
                  class="mt-3 mt-sm-4"
              >
              </notifications>

              <v-spacer/>

              <user_widget
                v-if="auth_user"
              ></user_widget>
              <v-skeleton-loader v-else-if="auth_user_api_call" type="avatar"/>
              <v-btn
                  v-else
                  class="mt-2"
                  :aria-label="$t('login')"
                  :title="$t('login')"
                  @click="login_dialog = true"
                  color="green"
                  icon
                  x-large
              >
                  <v-icon>mdi-account</v-icon>
              </v-btn>
          </div>
      </v-app-bar>

      <!-- the main left navigation -->
      <v-navigation-drawer
          app
          clipped
          mobile-breakpoint="650"
          v-model="selected_drawer_state"
          :mini-variant="selected_mini_drawer_state"
      >
          <v-list class="pl-0" >
              <v-list-item class="hidden-sm-and-up" @click="selected_drawer_state = false" style="border-bottom: 1px solid white">
                  <v-icon>mdi-backburger</v-icon>
              </v-list-item>
              <template v-for="(nav,key) in main_navigation">
                <v-list-item :key="key" class="pl-0" v-if="(nav.language_condition && $i18n.locale == nav.language_condition) || !nav.language_condition">
                    <router-link
                        v-if="!nav.menu"
                        :to="nav.route"
                        class="btn-link ml-4"
                        :aria-label="nav.label"
                    >
                        <v-icon :title="$t(nav.label)">{{ nav.icon }}</v-icon>
                        <transition name="fade" mode="out-in">
                          <span v-if="!selected_mini_drawer_state">
                              {{ $t(nav.label) }}
                          </span>
                        </transition>
                    </router-link>
                  <v-menu v-else offset-y>
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              text
                              left
                              v-bind="attrs"
                              v-on="on"
                              class="white--text"
                          >
                              <v-icon v-if="nav.icon" :title="$t(nav.label)">{{ nav.icon }}</v-icon>
                              <transition name="fade" mode="out-in">
                              <span v-if="!selected_mini_drawer_state">
                                  {{ $t(nav.label) }}
                                  <v-icon v-if="attrs['aria-expanded'] == 'false'" color="green">mdi-arrow-bottom-right</v-icon>
                                  <v-icon v-else color="green">mdi-arrow-top-left</v-icon>
                              </span>
                              </transition>
                          </v-btn>
                      </template>
                      <v-list>
                          <v-list-item v-for="(navmenu,key) in nav.menu" :key="key+'menu'">
                              <router-link
                                  class="btn-link ml-2"
                                  :to="navmenu.route"
                                  :aria-label="navmenu.label"
                              >
                                  <v-icon v-if="navmenu.icon" :title="$t(navmenu.label)">{{ navmenu.icon }}</v-icon>
                                  <v-img
                                      v-if="navmenu.flag"
                                      :lazy-src="require('./assets/flags/'+navmenu.flag)"
                                      :src="require('./assets/flags/'+navmenu.flag)"
                                      class="small-flag float-left mr-1 mt-1">
                                  </v-img>
                                  {{ $t(navmenu.label) }}
                              </router-link>
                          </v-list-item>
                      </v-list>
                  </v-menu>
              </v-list-item>
              </template>
              <v-list-item
                  v-if="$i18n.locale == 'en'"
                  @click="$i18n.locale = 'fi'; $cookies.set('locale', 'fi')"
              >
                      <v-img
                          :src="require('./assets/flags/fi.png')"
                          :alt="$t('finnish')"
                          width="20"
                          style="margin-left: 3px;margin-right: 5px"
                      />
                  <v-list-item-title>{{ $t('finnish') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="$i18n.locale == 'fi'"
                  @click="$i18n.locale = 'en'; $cookies.set('locale', 'en')">
                      <v-img
                          :src="require('./assets/flags/gb.png')"
                          :alt="$t('english')"
                          width="20"
                          style="margin-left: 3px;margin-right: 5px"
                      />
                  <v-list-item-title>{{ $t('english') }}</v-list-item-title>
              </v-list-item>
          </v-list>
          <div class="bottom-content" v-if="!selected_mini_drawer_state">
              <div class="mb-2">
              <router-link to="/service-info" class="white--text mr-1">{{ $t('service info') }}</router-link>
              <v-icon x-small class="mr-1">mdi-circle-small</v-icon>
              <router-link to="/terms-of-service" class="white--text mr-1 mb-1">{{ $t('terms of service') }}</router-link>
              </div>
              <div>
              <router-link to="/privacy-policy" class="white--text mr-1 mb-1">{{ $t('privacy policy') }}</router-link>
              <v-icon x-small class="white--text mr-1 mb-1">mdi-circle-small</v-icon>
              <router-link to="/contact" class="white--text">{{ $t('contact us') }}</router-link>
              </div>
          </div></v-navigation-drawer>

      <v-main>
        <v-container fluid>
            <keep-alive max="3">
                <router-view/>
            </keep-alive>
        </v-container>
    </v-main>

      <v-snackbar
          v-model="snackbar"
          :color="snackbar_color"
          timeout="5000"
      >
          {{ snackbar_message }}

      </v-snackbar>

      <!-- cookies dialog (in vue2, aria-label does not work -->
      <!--
      <v-dialog
          v-model="service_dialog"
          persistent
          max-width="550px"
          hide-overlay
          content-class="my-custom-dialog"
          :aria-label="$t('accept cookies')"
      >
      -->
        <div v-if="service_dialog" class="service-dialog" :aria-label="$t('accept cookies')" role="dialog">
            <v-card color="black" v-if="service_dialog == 'cookies'">
              <v-card-text class="pt-4 pb-1" >
                  <!-- cookie disclaimer -->
                  <template v-if="$i18n.locale == 'fi'">
                      <h2>Evästeiden käyttöehdot</h2>
                      <p>
                          Tämä sivusto käyttää evästeitä parantaakseen selailukokemustasi, tarjotakseen henkilökohtaista sisältöä ja mainoksia sekä analysoidakseen verkkosivuston liikennettä. Jatkamalla sivuston selailua hyväksyt evästeiden käytön EU:n standardien mukaisesti.
                          <router-link to="/privacy-policy">{{ $t('more about cookies') }}.</router-link>
                      </p>
                  </template>
                  <template v-else>
                      <h2>Cookie Disclaimer</h2>
                      <p>
                          This site uses cookies to enhance your browsing experience, provide personalized content and advertisements, and analyze website traffic. By continuing to browse this site, you are agreeing to our use of cookies in accordance with EU standards.
                          <router-link to="/privacy-policy">{{ $t('more about cookies') }}.</router-link>
                      </p>
                  </template>


              </v-card-text>

              <v-card-actions>
                  <v-btn
                      color="green darken-1" text
                      v-if="$i18n.locale == 'fi'"
                      @click="$i18n.locale = 'en'; $cookies.set('locale', 'en')"
                  >
                      {{ $t('use site english') }}
                  </v-btn>
                  <v-btn
                      color="green darken-1" text
                      v-if="$i18n.locale == 'en'"
                      @click="$i18n.locale = 'fi'; $cookies.set('locale', 'fi')"
                  >
                      {{ $t('use site finnish') }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="v-positive-button"
                      @click="service_dialog = false; $cookies.set('cookie_disclaimer', true, 604800)"
                  >
                      {{ $t('accept cookies') }}
                  </v-btn>

              </v-card-actions>

          </v-card>
            <v-card color="black" v-if="service_dialog == 'version'">
                <v-card-title>
                    {{ $t('new version detected') }}
                </v-card-title>
                <v-card-text>
                    {{ $t('js version changes', [serverVersion]) }}
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        class="v-positive-button"
                        @click="acceptVersion"
                    >
                        {{ $t('reload page') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="service_dialog = false;"
                    >
                        {{ $t('cancel') }}
                    </v-btn>

                </v-card-actions>
            </v-card>
      </div>
      <!--
      </v-dialog>
        -->
      <!-- login dialog -->
      <v-dialog
          v-model="login_dialog"
          max-width="500"
      >
          <login-form :login-prompt="login_prompt" @login_dialog="login_dialog = $event"/>
      </v-dialog>
      <!-- user collections dialog for logged un users -->
      <v-dialog
          v-model="collections_dialog"
          @click:outside="collections_item = false"
          max-width="900"
      >
          <v-card class="blue-grey darken-4">
              <user-collections :add_item="collections_item" :ts="collections_dialog" @close="collections_dialog = false;collections_item = false"/>
          </v-card>
      </v-dialog>
      <v-dialog
          v-model="searchDialog"
          scrollable
          max-width="750"
          content-class="search"
          overlay-opacity="0.6"
          :aria-label="$t('search')"
      >
        <master-search
            v-if="masterSearchPersistence"
            :searchPhraseParent="searchPhrase"
            @searchPhrase="searchPhrase = $event"
            @searchDialogClose="searchDialog = false"
        />
      </v-dialog>
  </v-app>
</template>

<script>

const UserWidget = () => import ("@/components/UserWidget")
const UserCollections = () => import ("@/components/UserCollections")
const Notifications = () => import ("@/components/Notifications")
const LoginForm = () => import ("@/components/LoginForm")
const MasterSearch = () => import ("@/components/MasterSearch")
import AppMixins from "@/mixins/app";
import imageMethods from "@/mixins/imageMethods";

export default {
    name: 'App',
    mixins: [AppMixins, imageMethods],
    components: {
        MasterSearch,
        LoginForm,
        Notifications,
        user_widget: UserWidget,
        UserCollections,
    },
    data: () => ({
        snackbar: false,
        snackbar_message: null,
        snackbar_color: null,
        main_navigation: [
            {route: '/', label: 'out now', icon: 'mdi-new-box'},
            {route: '/releases/upcoming', label: 'upcoming releases', icon: 'mdi-bullhorn-outline'},
            {label: 'releases', icon: 'mdi-disc', menu: [
                {route: '/releases', label: 'all', icon: 'mdi-check-underline'},
                {route: '/releases/4K', label: '4k releases', icon: 'mdi-video-4k-box'},
                {route: '/releases/bluray', label: 'Blu-ray releases', icon: 'mdi-high-definition-box'},
                {route: '/releases/dvd', label: 'DVD releases', icon: 'mdi-disc'},
                {route: '/releases/videotapes', label: 'videotapes', icon: 'mdi-vhs'},
                //{route: 'releases/laserdisc', label: 'laserdisc', icon: 'mdi-disc'},
                {route: '/labels', label: 'home video labels', icon: 'mdi-label-multiple'},
            ]},
            {language_condition: 'fi', label: 'fin releases', icon: 'mdi-disc', menu: [
                    {route: '/releases/finnish_video_releases', label: 'all', icon: 'mdi-check-underline'},
                    {route: '/releases/4K?co=4&co=96', label: '4k releases', icon: 'mdi-video-4k-box'},
                    {route: '/releases/bluray?co=4&co=96', label: 'Blu-ray releases', icon: 'mdi-high-definition-box'},
                    {route: '/releases/dvd?co=4&co=96', label: 'DVD releases', icon: 'mdi-disc'},
                    {route: '/releases/videotapes?co=4', label: 'videotapes', icon: 'mdi-vhs'},
                    {route: '/releases/finnish_censorship', label: 'censored releases', icon: 'mdi-vhs'},
                    {route: '/labels?co=4', label: 'home video labels', icon: 'mdi-label-multiple'},
                ]},
            //{id: 'streaming', label: 'now streaming', icon: 'mdi-alpha-s-circle-outline'},
            {label: 'movies tv', icon: 'mdi-movie-roll', menu: [
                    {id: 'Movies', route: '/movies?so=updated_desc', label: 'movies', icon: 'mdi-movie-roll'},
                    {id: 'TvShows', route: '/tv_shows', label: 'tv shows', icon: 'mdi-television'},
                    {id: 'TvEpisodes', route: '/tv_episodes', label: 'tv episodes', icon: 'mdi-television-classic'},
                    {id: 'MoviesReviewed', route: '/movies?fi=reviewed', label: 'reviewed', icon: 'mdi-star'},
                ]},
            {id: 'persons', label: 'cast crew', route: '/persons', icon: 'mdi-account-star-outline'},
            {id: 'reviews', label: 'reviews', icon: 'mdi-star', menu: [
                    {id: 'all_reviews', route: '/reviews?so=updated_desc', label: 'all', icon: 'mdi-check-underline'},
                    {id: 'english_reviews', route: '/reviews?la=1', label: 'english reviews', flag: 'us.png'},
                    {id: 'finnish_reviews', route: '/reviews?la=5', label: 'finnish reviews', flag: 'fi.png'},
                    {id: 'video_reviews', route: '/reviews?fi=video', label: 'video reviews', icon: 'mdi-video'},
                    {id: 'release_reviews', route: '/reviews?fi=release', label: 'release reviews', icon: 'mdi-disc'},

                ]},
            {id: 'posts', route: '/posts', label: 'discussions', icon: 'mdi-chat'},
            //{id: 'production_companies', label: 'production companies', icon: 'mdi-alpha-c-circle-outline'},
            //{id: 'videos', label: 'videos', icon: 'mdi-alpha-v-circle-outline'}, //perhaps this could be something like video talk to boost latest videotalk
            //{id: 'pictures', label: 'pictures', icon: 'mdi-alpha-p-box-outline'},
            {label: 'community', icon: 'mdi-account-group', menu: [
                {id: 'Users', route: '/users', label: 'users', icon: 'mdi-account-group'},
                {id: 'Collections', route: '/collections', label: 'collections', icon: 'mdi-playlist-check'},
            ]},
            //{id: 'stream', label: 'stream', icon: 'mdi-alpha-s-box-outline'},
            //{id: 'search', label: 'super search', icon: 'mdi-alpha-s-circle-outline'},
        ],
        masterSearchPersistence: false,
        searchPhrase: null,
        searchDialog: false,
        notifications: null,
        selected_drawer_state: false, //is drawer visible or hidden
        selected_mini_drawer_state: true, //is drawer in mini mode or not
        service_dialog: false,
        login_dialog: false,
        login_prompt: false,
        collections_dialog: false,
        collections_item: false,
        serverVersion: false,
    }),
    watch: {
        searchDialog(){
            !this.masterSearchPersistence ? this.masterSearchPersistence = true : null
        },
        searchPhrase(){
            this.searchPhrase ? this.searchDialog = true : null;
        }
    },
    computed: {

        drawerResponsive() {

            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return false
                case 'sm':
                    return true
                case 'md':
                    return true
                case 'lg':
                    return true
                case 'xl':
                    return true

                default:
                    return true
            }
        },

        miniDrawerResponsive() {

            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return false
                case 'sm':
                    return true
                case 'md':
                    return true
                case 'lg':
                    return false
                case 'xl':
                    return false

                default:
                    return false
            }
        },

    },
    methods: {
        acceptVersion(){
            //localStorage.setItem("appVersion", this.serverVersion);
            location.reload();
        },
        goAndClose(page){
            this.goAndClose = false
            this.$cookies.set('cookie_disclaimer', true)
            this.$router.push('/'+page)
        },
        logout() {

            this.axios.post('/logout')
                .then(() => {

                    window.Echo.leave('user.'+this.authUser.id)
                    this.$store.commit('authUser', null) //reset in vuex
                    delete this.axios.defaults.headers.common["Authorization"] //remove authorization
                    this.axios.defaults.headers.common = {'Accept': `application/json, text/plain, */*`} //restore application json

                })
                .catch(e => {
                    console.log(e)
                });

        },
        me() {

            this.axios.get('/me') //if user is set, store to vuex and start listening events
                .then(response => {

                    this.$store.commit('authUser', response.data) //set in vue
                    //also get user persistent notifications in me query and emit them for the notification
                    //this.userListener() //enable real time notifications (and videospace event)
                    this.$store.commit('authUserApiCall', false) //disable api call when user is loaded
                })
                .catch(() => {
                    this.$store.commit('authUserApiCall', false) //disable api call when user is not loaded
                    this.$cookies.remove('login_remember') //remove remember
                });

        },


        /*
            version() each update must have revised version in production
        */

        async version() {
            //todo version is 1.0.4.5
            let clientVersion = "1.0.4.5"
            const currentTimestamp = new Date().getTime();

            this.axios
                .get("../version.json", {params: {ts: currentTimestamp}})
                .then((response) => {
                    if (response.data.version) {

                        var serverVersion = response.data.version;
                        console.log("version() ", clientVersion, serverVersion);

                        //if serverVersion is different to clientVersion, prompt user to update
                        if (clientVersion !== serverVersion) {
                            this.serverVersion = serverVersion;
                            this.service_dialog = 'version'
                        }
                    }
                }).catch((e)=>{
                    console.log('cant access version ',e)
                    console.log("version() error ", clientVersion);
                })
        },

    },
    mounted() {

        //if capacitor is not present (meaning that not a mobile app)
        if (!window.Capacitor) {
            // Check for updates on page load
            //this.version();
            // Check for updates every 6 minutes
            //setInterval(this.version, 10 * 60 * 600);
            //setInterval(this.version, 10 * 60 * 600);
            setInterval(this.version, 60 * 60 * 500);
        }

        //react to snackbar events
        this.$root.$on('snackbar', function(payload){

            this.snackbar = true
            this.snackbar_message = payload.message;
            this.snackbar_color = payload.color;

        }.bind(this))

        //run tasks upon creation when registered
        this.$root.$on('login-prompt', () => {
            this.login_dialog = true
            this.login_prompt = true
        })

        //when want to put something to the collections
        this.$root.$on('user-collections', function(item, ){
            if(!this.auth_user){
                this.login_dialog = true
                this.login_prompt = true
            }else{
                this.collections_dialog = true
                this.collections_item = item
            }

        }.bind(this))
    },
    created(){
        //change language on cookie
        if(this.$cookies.get('locale')) this.$i18n.locale = this.$cookies.get('locale')

        //if login_remember is set, get logged in user
        if(this.$cookies.get('login_remember')){
            this.axios.defaults.headers.common = {'Authorization': this.$cookies.get('login_remember')}
            this.me()
        }else
            this.$store.commit('authUserApiCall', false)

        if(!this.$cookies.get('cookie_disclaimer')) this.service_dialog = 'cookies'
        this.selected_drawer_state = this.drawerResponsive
        this.selected_mini_drawer_state = this.miniDrawerResponsive

        //start listening videosspace events
        //this.videospaceListener()
    }
};
</script>
<style lang="scss">
@import "styles/layout.scss";
</style>
<style scoped>
>>> .search{
    align-self: flex-start;
}
>>> .my-custom-dialog {
    align-self: flex-end;
    margin-bottom: 70px;
    border: 1px solid grey;
}
.bottom-content {
    position: absolute;
    bottom:0px;
    font-size: 0.8em;
    margin:10px;
}
.service-dialog{
    position: fixed;
    z-index:10;
    bottom:10px;
    left:260px;
    width: 600px;
    border: 2px white solid;
    border-radius: 5px;
}
.btn-link{
    color: white !important;
    text-transform: uppercase;
    text-decoration: none;
}
@media screen and (max-height: 600px) {
    /* Adjust bottom content position for smaller screens */
    .bottom-content {
        position: inherit;
        font-size: 0.9em;
    }

}
/* xs */
@media (max-width: 767px) {
    .service-dialog{
        left:0px;
        width:initial;
        border-radius: initial;
    }
}

</style>

