import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import cookie from './plugins/cookie'
import i18n from './locales/i18n'
//import './plugins/understand' //disabled for now
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
    // Define your global default title and meta tags here
    // These will be used when components don't specify their own values
    refreshOnceOnNavigation: true,
    defaultTitle: 'Videospace',
    defaultTitleTemplate: '%s | Videospace',
    defaultMetaInfo: {
        // Default meta tags for description, keywords, etc.
        description: 'Videospace',
        // Add more default meta tags as needed
    },
})
import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
    config: { id: "G-QPK97LVT1P" }
});

import './plugins/dayjs'
Vue.config.productionTip = false
/* without Gtag:
router.afterEach((to) => {
    window.gtag('config', window.GA_TRACKING_ID, {
        page_path: to.fullPath,
        app_name: 'Videospace app',
        send_page_view: true,
    });
});
*/
new Vue({
    router,
    store,
    vuetify,
    cookie,
    i18n,
    render: h => h(App)
}).$mount('#app')

